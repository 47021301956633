
export default class HorarioUtils {

    converterHoraParaMinutos(hora: string): number {
        const [horas, minutos] = hora.split(':').map(Number);
        return horas * 60 + minutos;
    }

    converterHoraParaDate(hora: string): Date {
        return new Date(`2000-01-01T${hora}Z`);
    }

    calcularDiferencaEntreHoras(horaInicial: string, horaFinal: string) {
        const horaInicio = this.converterHoraParaDate(horaInicial);
        const horaFim = this.converterHoraParaDate(horaFinal);

        if (horaFim > horaInicio) {
            // @ts-ignore
            return Math.abs(horaInicio - horaFim) / 36e5;
        }

        return 0;
    }

    isHoraFinalMaior(horaInicial: string, horaFinal: string) {
        const minutosHoraInicial = this.converterHoraParaMinutos(horaInicial);
        const minutosHoraFinal = this.converterHoraParaMinutos(horaFinal);
        return minutosHoraFinal > minutosHoraInicial;
    }

    isHoraValida(horaValor: string) {
        const hora = Number(horaValor?.split(":")[0]);
        const minuto = Number(horaValor?.split(":")[1]);
        return hora <= 23 && minuto <= 59;
    }

}