import ListView from "../../component/ListView";
import {Column} from "primereact/column";
import Restaurante from "../../model/Restaurante";
import CpfCnpjUtils from "../../utils/CpfCnpjUtils";
import * as React from "react";
import {useRef} from "react";
import {MenuItem} from "primereact/menuitem";
import {PrimeIcons} from "primereact/api";
import {useNavigate} from 'react-router-dom';
import rest from "../../utils/EnvConfig";
import useToken from "../../hook/useToken";
import {TipoUsuario} from "../../model/TipoUsuario";
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import styles from "./Restaurante.module.css";

function RestauranteList() {
    const {token} = useToken();
    const navigate = useNavigate();
    const fotosMenuRef = useRef([]);
    const cardapiosMenuRef = useRef<Menu>(null);
    fotosMenuRef.current = [];

    const cpfCnpjTemplate = (restaurante: Restaurante) => {
        return CpfCnpjUtils.formatarCampoString(restaurante.cpfCnpj);
    }

    const obterRecursoCorreto = (): string | undefined => {
        if (token && token.tipo === TipoUsuario.ADMINISTRADOR) {
            return `${rest.restaurante}/${rest.obterTodos}`;
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            return `${rest.restaurante}/${rest.restaurantesPorUsuario}${token.usuarioId}`;
        }

        return "";
    }

    const itensCardapiosMenuBotoesExtras: MenuItem[] = [
        {
            label: 'Cardápio de comida',
            icon: 'pi pi-file-edit',
            command: () => {
                navigate(`/restaurante/cardapio/comida`);
            }
        },
        {
            label: 'Cardápio de bebida',
            icon: 'pi pi-file-edit',
            command: () => {
                navigate(`/restaurante/cardapio/bebida`);
            }
        }
    ];

    const botoesPrincipaisExtra = [
        (
            <>
                <Menu model={itensCardapiosMenuBotoesExtras} className={styles.menuFotos} popup ref={cardapiosMenuRef} />
                <Button icon="pi pi-pen-to-square" className={`${styles.espacamentoBotoes} mr-2`} raised size={"large"}
                        label={"Cardápios"}
                        // @ts-ignore
                        title={"Cadastro de cardápios"} onClick={(event: any) => cardapiosMenuRef.current.toggle(event)}
                        severity="success" />
            </>
        )];

    const botoesExtra = (entidade: Restaurante, rowIndex?: number): React.JSX.Element[] => {
        return [
            (
                <>
                    <Button icon={PrimeIcons.CAMERA} className={`${styles.espacamentoBotoes} mr-2`} raised
                                 title={"Envio de fotos e logo do restaurante"} onClick={(event: any) => navigate(`/restaurante/imagem/${entidade.id}`)}
                                 severity="success" />
                </>
            )];
    }

    return (
        <>
            <ListView title={"Restaurante"}
                      botoesAdicionais={botoesExtra}
                      botoesPrincipaisAdicionais={botoesPrincipaisExtra}
                      key={"restauranteKey"}
                      restCustom={obterRecursoCorreto()}
                      id={`${rest.restaurante}`}>

                {token.tipo === TipoUsuario.ADMINISTRADOR ?
                    [
                        <Column field="nomeEstabelecimento" header="Nome do estabelecimento" sortable/>,
                        <Column field="razaoSocial" header="Razão social" sortable/>,
                        <Column field="cpfCnpj" header="CPF/CNPJ" body={cpfCnpjTemplate} sortable/>
                    ]
                    :
                    [
                        <Column field="nomeEstabelecimento" header="Nome" sortable/>,
                        <Column field="enderecoCompleto" header="Endereço" sortable/>
                    ]
                }
            </ListView>
        </>
    );
}

export default RestauranteList;