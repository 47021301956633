import React, {useContext, useEffect, useState} from "react";

import EditView from "../../component/EditView";

import Diaria from "../../model/Diaria";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import FormUtils from "../../utils/FormUtils";
import useToken from "../../hook/useToken";
import {useNavigate} from "react-router-dom";
import {TipoUsuario} from "../../model/TipoUsuario";
import rest from "../../utils/EnvConfig";
import {Ajax} from "../../utils/Ajax";
import Restaurante from "../../model/Restaurante";
import MultiCombo from "../../component/form/multi-combo/MultiCombo";
import TipoServico from "../../model/TipoServico";
import Combo from "../../component/form/combo/Combo";
import Calendario from "../../component/form/calendario/Calendario";
import Texto from "../../component/form/texto/Texto";
import MoedaBancario from "../../component/form/moeda-bancario/MoedaBancario";
import Switch from "../../component/form/switch/Switch";
import moment, {Moment} from "moment";
import {InputMask} from "primereact/inputmask";
import styles from "../restaurante/Restaurante.module.css";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import TextoMascara from "../../component/form/mascara/TextoMascara";
import HorarioUtils from "../../utils/HorarioUtils";
import {Calendar} from "primereact/calendar";

function DiariaEdit() {
    const {error} = useContext(MensagemContext) as MensagemContextType;
    const [entidade, setEntidade] = useState<Diaria>(new Diaria());
    const [restaurantes, setRestaurantes] = useState<Restaurante[]>([]);
    const [tiposServicos, setTiposServicos] = useState<TipoServico[]>([]);
    const [status, setStatus] = useState(entidade.status ? entidade.status : true);
    const {token} = useToken();
    const navigate = useNavigate();
    const horarioUtils = new HorarioUtils();
    const [quantidadeHoras, setQuantidadeHoras] = useState<number>(0);

    useEffect(() => {
        if (token && token.tipo !== TipoUsuario.ADMINISTRADOR) {
            navigate("/sem-permissao");
            return;
        }

        Ajax.of()
            .error(error)
            .obterTodosCustom(obterRecursoRestaurantesCorreto(), (data) => {
                setRestaurantes(data);
            });

        Ajax.of()
            .error(error)
            .obterTodos(`${rest.tipoServico}`,
                (data) => setTiposServicos(data));
    }, []);

    useEffect(() => {
        calcularHorasTrabalho();
    }, [entidade]);

    const obterRecursoRestaurantesCorreto = (): string | undefined => {
        if (token && token.tipo === TipoUsuario.ADMINISTRADOR) {
            return `${rest.restaurante}/${rest.obterTodos}`;
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            return `${rest.restaurante}/${rest.restaurantesPorUsuario}${token.usuarioId}`;
        }

        return "";
    }

    const editEntidade = (diaria: Diaria) => {
        setEntidade(diaria);
        if (diaria.data) {
            const novaData: Moment = moment(diaria.data, "YYYY-MM-DD");
            setEntidade({ ...diaria, data: novaData.toDate() });
        }
    }

    const entidadeOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, [e.target.id]: e.target.value });
    }

    const calcularHorasTrabalho = () => {
        if (entidade.horaInicio && entidade.horaFim) {
            setQuantidadeHoras(horarioUtils.calcularDiferencaEntreHoras(entidade.horaInicio, entidade.horaFim));
        }
    }

    const entidadeMoedaOnChangeHandler = (value: number, valueCorrigido?: number) => {
        setEntidade({ ...entidade, valorPagamento: valueCorrigido });
    }

    const switchStatusOnChangeHandler = (e: any) => {
        setStatus(e.value);
        setEntidade({ ...entidade, status: e.value });
    }

    const validar = async (): Promise<boolean> => {
        if (!entidade.restaurantes) {
            FormUtils.invalidarComponente(error, "Restaurantes é obrigatório");
            return false;
        }

        if (entidade.restaurantes && entidade.restaurantes.length === 0) {
            FormUtils.invalidarComponente(error, "Informe ao menos um restaurante");
            return false;
        }

        if (!entidade.tipoServico) {
            FormUtils.invalidarComponente(error, "Tipo de serviço é obrigatório");
            return false;
        }

        if (!entidade.data) {
            FormUtils.invalidarComponente(error, "Data do serviço é obrigatório");
            return false;
        }

        if (!entidade.horaInicio) {
            FormUtils.invalidarComponente(error, "Horário inicial do serviço é obrigatório");
            return false;
        }

        if (!entidade.horaFim) {
            FormUtils.invalidarComponente(error, "Horário final do serviço é obrigatório");
            return false;
        }

        if (!horarioUtils.isHoraFinalMaior(entidade.horaInicio, entidade.horaFim)) {
            FormUtils.invalidarComponente(error, "Horário inicial não pode ser maior que o horário final");
            return false;
        }

        if (!horarioUtils.isHoraValida(entidade.horaInicio)) {
            FormUtils.invalidarComponente(error, "Horário inicial é inválido. Formato deve ser de 00:00 até 23:59");
            return false;
        }

        if (!horarioUtils.isHoraValida(entidade.horaFim)) {
            FormUtils.invalidarComponente(error, "Horário final é inválido. Formato deve ser de 00:00 até 23:59");
            return false;
        }

        if (!entidade.observacao) {
            FormUtils.invalidarComponente(error, "Detalhes do serviço é obrigatório");
            return false;
        }

        return true;
    }

    return (
        <>
            {token && token.tipo === TipoUsuario.ADMINISTRADOR && (
                <EditView title={"Diária"}
                          id={`${rest.diaria}`}
                          entidade={entidade}
                          onValidationHandler={validar}
                          entidadeUseStateFunction={editEntidade}>

                    <MultiCombo values={restaurantes} id={"restaurantes"}
                                label={"Selecione os restaurantes"} value={entidade.restaurantes}
                                optionLabel={"nomeEstabelecimento"}
                                onChangeHandler={entidadeOnChangeHandler}/>

                    <Combo id={"tipoServico"} label={"* Tipo de serviço"}
                           optionLabel={"nomeServico"}
                           placeHolder={"Selecione o tipo de serviço"}
                           onChangeHandler={entidadeOnChangeHandler}
                           value={entidade.tipoServico} values={tiposServicos}/>

                    <Calendario id={"data"} label={"* Data do serviço"} value={entidade.data}
                                minDate={new Date()}
                                onChangeHandler={entidadeOnChangeHandler}/>


                    <div className={`${styles.espacamento} p-fluid grid`}>
                        <div className={"field col-12 md:col-2"}>
                            <label className={styles.label} htmlFor={"horaInicio"}>Hora inicial</label>
                            <span>
                                <InputMask id={"horaInicio"} mask={"99:99"} placeholder={"Hora inicial"}
                                           style={{width: "205px"}} name={"horaInicio"}
                                           value={entidade.horaInicio}
                                           onBlur={(e: any) =>
                                               setEntidade({...entidade, horaInicio: e.target.value})}
                                />
                            </span>
                        </div>

                        <div className={"field col-12 md:col-2"}>
                            <label className={styles.label} htmlFor={"horaFim"}>Hora final</label>
                            <span>
                                <InputMask id={"horaFim"} mask={"99:99"} placeholder={"Hora final"}
                                           style={{width: "205px"}} name={"horaFim"}
                                           value={entidade.horaFim}
                                           onBlur={(e: any) =>
                                               setEntidade({...entidade, horaFim: e.target.value})}
                                />
                            </span>
                        </div>
                    </div>

                    <label className={styles.label}>Quantidade de horas: {quantidadeHoras.toFixed(2)}h </label>

                    <br />
                    <br />
                    <br />
                    <br />

                    <div><i>* Para valor "a combinar", não preencher.</i></div>
                    <MoedaBancario id={"valorPagamento"} label={"Valor do pagamento"} value={entidade.valorPagamento}
                                   maxLength={999999}
                                   onValueChangeHandler={entidadeMoedaOnChangeHandler}/>


                    <Texto id={"observacao"} label={"* Detalhes do serviço a ser executado"} value={entidade.observacao}
                           placeholder={"Ex: Preciso de um garçom com experiência."}
                           maxLength={500}
                           onChangeHandler={entidadeOnChangeHandler}/>

                    <Switch checked={status} id={"status"}
                            label={status ? "Ativo" : "Inativo"}
                            onChangeHandler={switchStatusOnChangeHandler}/>

                </EditView>
            )}
        </>
    );
}

export default DiariaEdit;